<template>
  <TransitionRoot as="template" :show="isAchievementModalOpen">
    <Dialog
      as="div"
      auto-reopen="true"
      class="fixed z-10 inset-0 overflow-y-auto select-none"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              fixed
              inset-0
              dark:bg-true-gray-900 dark:bg-opacity-75
              bg-warm-gray-900 bg-opacity-75
            "
          />
        </TransitionChild>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              dark:bg-true-gray-800
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
            "
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4"></div>
            <div class="sm:flex sm:items-start">
              <div
                class="
                  mx-auto
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-full
                  bg-red-100
                  sm:mx-0 sm:h-10 sm:w-10
                "
              >
                <!-- <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" /> -->
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="
                    text-lg
                    leading-6
                    font-medium
                    dark:text-gray-200
                    text-warm-gray-900
                  "
                >
                  You achieved {{ achievement.name }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-warm-gray-500 dark:text-warm-gray-400">
                    {{ achievement.description }} <strong>not</strong> be saved. You can
                    always come back later to start a new Mock Exam session.
                  </p>
                  <p
                    class="
                      text-sm text-gray-500
                      dark:text-warm-gray-400
                      max-w-sm
                      mt-4
                    "
                  >
                    You received: {{ achievement.skillpoints }} Skillpoints and a fucking hand shake, how about that?
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                @click="closeAchievementModal"
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-red-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-red-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-red-500
                  sm:ml-3 sm:w-auto sm:text-sm
                "
              >
                {{ achievementModalCounter == 0 ? "Complete" : "Next" }}
              </button>
              <!-- @click="(isOpen = false), (exam.status = 'started')" -->
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
 
<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
export default {
  props: ["isAchievementModalOpen", "achievementModalCounter", "achievement"],
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {
    closeAchievementModal() {
      this.$emit("closeAchievementModal");
    },
  },
};
</script>