function getTitle(vm) {
  const { site_title } = vm.$options
  if (site_title) {
    return typeof site_title === 'function'
      ? site_title.call(vm)
      : site_title
  }
}
export default {
  created() {
    const site_title = getTitle(this)
    if (site_title) {
      document.title = site_title + " | " + location.host
    }
  }
}