<template>
  <p class="text-sm font-medium text-warm-gray-500">
    Exam is now complete. Here are the results:
    {{ exam }}
  </p>

  <!-- Exit Button -->
  <button
    :disabled="achievementModalCounter >= 0"
    @click="this.$router.push({ path: '/' })"
    type="button"
    :class="[
      achievementModalCounter >= 0
        ? 'bg-gray-600 hover:bg-gray-700'
        : 'bg-indigo-600 hover:bg-indigo-700',
      'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm',
    ]"
  >
    <!-- Button Text -->
    {{ achievementModalCounter >= 0 ? "PLEASE WAIT" : "EXIT EXAM" }}
  </button>
</template>
 
<script>
export default {
  props: ["exam", "achievementModalCounter"],
};
</script>

