<template>
  <div
    class="text-sm font-medium text-warm-gray-500"
  >
    <p class="my-8">Exam is now ongoing.</p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <p class="my-8">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus
      corrupti enim quas excepturi ullam, ut doloribus ducimus amet rerum, totam
      dicta numquam, fugiat labore! Inventore soluta qui blanditiis molestiae.
      Possimus.
    </p>
    <button
      @click="this.confirmQuit()"
      type="button"
      class="
        w-full
        inline-flex
        justify-center
        rounded-md
        border border-transparent
        shadow-sm
        px-4
        py-2
        bg-red-600
        text-base
        font-medium
        text-white
        hover:bg-red-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
        sm:ml-3 sm:w-auto sm:text-sm
      "
    >
      QUIT EXAM
    </button>
    <button
      @click="this.evaluateExam()"
      type="button"
      class="
        w-full
        inline-flex
        justify-center
        rounded-md
        border border-transparent
        shadow-sm
        px-4
        py-2
        bg-blue-600
        text-base
        font-medium
        text-white
        hover:bg-blue-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
        sm:ml-3 sm:w-auto sm:text-sm
      "
    >
      COMPLETE EXAM
    </button>
  </div>
</template>
 
<script>
export default {
  props: ["exam"],
  methods: {
    evaluateExam() {
      this.$emit("evaluateExam");
    },
    confirmQuit() {
      this.$emit("confirmQuit");
    },
  },
};
</script>

