<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="365"
    height="408"
    viewBox="0 0 365 408"
    fill="none"
  >
    <path
      d="M160.178 407.26H102.759V96.7148L128.962 119.472C148.791 136.693 160.178 161.666 160.178 187.928V407.26Z"
      fill="currentColor"
    />
    <path
      d="M205.519 407.26H262.937V96.7148L236.734 119.472C216.906 136.693 205.519 161.666 205.519 187.928V407.26Z"
      fill="currentColor"
    />
    <path
      d="M0 368.725H57.4244V217.608L29.9388 254.497C10.5 280.586 0 312.252 0 344.786V368.725Z"
      fill="currentColor"
    />
    <path
      d="M364.605 368.725H307.181V217.608L334.667 254.497C354.105 280.586 364.605 312.252 364.605 344.786V368.725Z"
      fill="currentColor"
    />
    <path
      d="M143.561 46.8462L182.851 0L222.142 46.8462C215.593 51.3797 198.265 60.4467 181.34 60.4467C164.415 60.4467 149.102 51.3797 143.561 46.8462Z"
      fill="currentColor"
    />
  </svg>
</template>