import { createRouter, createWebHistory } from 'vue-router'
import firebase from "@/firebase/config";
import "firebase/auth";

import Dashboard from '../views/Dashboard.vue'
import Exam from '../views/Exam.vue'
import Practice from '../views/Practice.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'

const routes = [
  {
    path: '/', beforeEnter: authGuard,
    name: 'Dashboard', component: Dashboard, 
  },
  {
    path: '/exam', beforeEnter: authGuard,
    name: 'Exam', component: Exam, 
  },
  {
    path: '/practice', beforeEnter: authGuard,
    name: 'Practice', component: Practice, 
  },
  {
    path: '/login', beforeEnter: redirectIfLogged, 
    name: 'Login', component: Login
  },
  {
    path: '/logout', beforeEnter: authGuard, 
    name: 'Logout', component: Logout, 
  },
  {
    path: '/:pathMatch(.*)*', beforeEnter: redirectIfLogged,
    component: Logout
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function authGuard(to, from, next) {
  const isAuthenticated = firebase.auth().currentUser;
  (!isAuthenticated) ? next({ path: '/login', query: { unauthorized: 'true' } }) : next();
}

function redirectIfLogged(to, from, next) {
  const isAuthenticated = firebase.auth().currentUser;
  (isAuthenticated) ? next({ path: '/' }) : next();
}

export default router