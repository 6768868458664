<template>
  <LoadingScreen v-if="isLoading" />
  <main v-else class="min-h-screen bg-warm-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <Logo class="text-warm-gray-900 w-12 h-12 mx-auto" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-warm-gray-900">
        Login to your account!
      </h2>
      <p class="mt-2 text-center text-sm text-warm-gray-600">
        Or if you haven't yet,
        {{ " " }}
        <a href="#" class="font-medium text-yellow-600 hover:text-yellow-500">
          Register for free
        </a>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form @submit.prevent="login" class="space-y-6">
          <div>
            <label for="email" class="block text-sm font-medium text-warm-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input
                v-model="email"
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                class="appearance-none block w-full px-3 py-2 border border-warm-gray-300 rounded-md shadow-sm placeholder-warm-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-warm-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input
                v-model="password"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                class="appearance-none block w-full px-3 py-2 border border-warm-gray-300 rounded-md shadow-sm placeholder-warm-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm">
              <a href="#" class="font-medium text-yellow-600 hover:text-yellow-500">
                Forgot your password?
              </a>
            </div>
          </div>

          <!-- Login Button -->
          <div>
            <button
              v-if="!isWaiting"
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-warm-gray-600 hover:bg-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Sign in
            </button>
            <button
              v-else
              disabled
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-warm-gray-600 hover:bg-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:opacity-50 disabled:cursor-auto"
            >
              <LoadingIcon class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              Signing in...
            </button>
          </div>

          <!-- Error Message -->
          <div v-if="error" class="rounded-md bg-yellow-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationIcon
                  class="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-yellow-800">
                  {{ error.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    @click="error = null"
                    type="button"
                    class="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yelow-50 focus:ring-yelow-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </main>
</template>

<script>
const NAME = "Login";

// Firebase Deps
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/functions";

// Components Deps
import { ExclamationIcon, XIcon } from "@heroicons/vue/solid";
import Logo from "@/components/icons/Logo";
import LoadingIcon from "@/components/icons/LoadingIcon";
import LoadingScreen from "@/components/screens/LoadingScreen";

export default {
  name: NAME,
  site_title: NAME,
  components: {
    ExclamationIcon, XIcon,
    Logo, LoadingIcon, LoadingScreen
  },
  data() {
    return {
      isLoading: null, // Loading means the view is being loaded. Show a loading screen
      isWaiting: null, // Waiting means the view is doing something. Adjust the UI if needed
      email: null,
      password: null,
      error: null,
    };
  },
  mounted() {
    this.isLoading = true;
    // Do some pre render scripts here
    this.isLoading = false;
  },
  methods: {
    // Login script
    login() {
      this.isWaiting = true;
      console.log("Logging in...");
      // Login via Firebase
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((data) => {

          firebase.auth().currentUser.getIdTokenResult()
            .then((tokenResult) => {
              console.log(`Claims retreived for user => `, tokenResult.claims)

              if(tokenResult.claims.admin){
                console.log("User is now logged in => ", data);
                this.$router.go();
              }else{
                firebase.auth().signOut()
                  .then(() => {
                    console.log("User is not an admin");
                    console.log("User is now logged out.");
                    this.$router.go({ path: "/login" });
                    // this.isLoading // Omitted since user is redirected
                  })
              }
            })
        })
        .catch((error) => {
          this.error = error;
          this.isWaiting = false;
        });
    },
  }
};
</script>