<template>
  <LoadingScreen v-if="isLoading" />
</template>

<script>
const NAME = "Logout";

// Firebase Deps
import firebase from "@/firebase/config";
import "firebase/auth";

// Components Deps
import LoadingScreen from "@/components/screens/LoadingScreen"

export default {
  name: NAME,
  site_title: NAME,
  components: {
    LoadingScreen
  },
  data() {
    return {
      isLoading: null,
    };
  },
  mounted() {
    this.isLoading = true;
    this.signOut();
  },
  methods: {
    signOut() {
      console.log("Logging out user.");
      firebase.auth().signOut()
        .then(() => {
          console.log("User is now logged out.");
          this.$router.go({ path: "/login" });
          // this.isLoading // Omitted since user is redirected
        })
        .catch(() => {
          console.error("Error trying to log out user.");
          // TODO: Handle Error
        });
    },
  },
};
</script>

<style scoped>
</style>