<template>
  <div
    v-if="!isLogged || $route.name == 'Logout' || $route.name == 'Login'"
    class="h-screen dark:bg-warm-gray-50 bg-warm-gray-50 overflow-hidden"
  >
    <router-view></router-view>
  </div>
  <div
    v-else
    class="
      relative
      h-screen
      flex
      dark:bg-true-gray-900
      bg-warm-gray-50
      overflow-hidden
    "
  >
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 lg:hidden"
        @close="mobileMenuOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              fixed
              inset-0
              dark:bg-true-gray-900 dark:bg-opacity-75
              bg-warm-gray-600 bg-opacity-75
            "
          />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              dark:bg-true-gray-700
              bg-white
              focus:outline-none
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-4">
                <button
                  type="button"
                  @click="mobileMenuOpen = false"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="pt-5 pb-4">
              <div class="flex-shrink-0 flex items-center px-4">
                <Logo
                  class="h-8 w-auto dark:text-true-gray-400 text-warm-gray-800"
                  aria-hidden="true"
                />
              </div>
              <nav aria-label="Sidebar" class="mt-5">
                <div class="px-2 space-y-1">
                  <router-link
                    v-for="item in navigation"
                    :key="item.name"
                    :to="item.href"
                    @click="mobileMenuOpen = false"
                    :class="[
                      item.name == $route.name
                        ? 'bg-warm-gray-50 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-400'
                        : 'hover:bg-warm-gray-50 hover:text-warm-gray-900 dark:hover:bg-true-gray-600 dark:hover:text-true-gray-400 ',
                      'group p-2 rounded-md flex items-center text-base font-medium dark:text-true-gray-400 text-warm-gray-600',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      aria-hidden="true"
                      class="
                        mr-4
                        h-6
                        w-6
                        text-warm-gray-400
                        group-hover:text-warm-gray-500
                        dark:group-hover:text-true-gray-400
                      "
                    />
                    {{ item.name }}
                  </router-link>

                  <button
                    @click="(mobileMenuOpen = false), this.toggleDarkMode()"
                    class="
                      w-full
                      hover:bg-warm-gray-50 hover:text-warm-gray-900
                      dark:hover:bg-true-gray-600 dark:hover:text-true-gray-400
                      group
                      p-2
                      rounded-md
                      flex
                      items-center
                      text-base
                      font-medium
                      dark:text-true-gray-400
                      text-warm-gray-600
                    "
                  >
                    <SunIcon
                      v-if="this.isDarkMode"
                      class="
                        mr-4
                        h-6
                        w-6
                        text-warm-gray-400
                        group-hover:text-warm-gray-500
                        dark:group-hover:text-true-gray-400
                      "
                      aria-hidden="true"
                    />
                    <MoonIcon
                      v-else
                      class="
                        mr-4
                        h-6
                        w-6
                        text-warm-gray-400
                        group-hover:text-warm-gray-500
                        dark:group-hover:text-true-gray-400
                      "
                      aria-hidden="true"
                    />
                    <span v-if="this.isDarkMode">Light Mode</span>
                    <span v-else>Dark Mode</span>
                  </button>

                  <router-link
                    to="/logout"
                    @click="mobileMenuOpen = false"
                    class="
                      hover:bg-warm-gray-50 hover:text-warm-gray-900
                      dark:hover:bg-true-gray-600 dark:hover:text-true-gray-400
                      group
                      p-2
                      rounded-md
                      flex
                      items-center
                      text-base
                      font-medium
                      dark:text-true-gray-400
                      text-warm-gray-600
                    "
                  >
                    <LogoutIcon
                      class="
                        mr-4
                        h-6
                        w-6
                        text-warm-gray-400
                        group-hover:text-warm-gray-500
                        dark:group-hover:text-true-gray-400
                      "
                      aria-hidden="true"
                    />
                    Logout
                  </router-link>
                </div>
              </nav>
            </div>
            <div
              class="
                flex-shrink-0 flex
                border-t
                dark:border-true-gray-800
                border-warm-gray-200
                p-4
              "
            >
              <router-link
                to="/account"
                @click="mobileMenuOpen = false"
                href="#"
                class="flex-shrink-0 group block"
              >
                <div class="flex items-center">
                  <div>
                    <DefaultUserIcon class="inline-block mx-auto h-10 w-10" />
                  </div>
                  <div class="ml-3">
                    <p
                      class="
                        text-base
                        font-medium
                        text-warm-gray-700
                        dark:text-true-gray-200
                        group-hover:text-warm-gray-900
                        dark:group-hover:text-true-gray-400
                      "
                    >
                      {{ user.email }}
                    </p>
                    <p
                      class="
                        text-sm
                        font-medium
                        text-warm-gray-500
                        dark:text-true-gray-400
                        group-hover:text-warm-gray-700
                        dark:group-hover:text-warm-gray-500
                      "
                    >
                      Account Settings
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="flex flex-col w-20">
        <div
          class="
            flex-1 flex flex-col
            min-h-0
            overflow-y-auto
            dark:bg-true-gray-800
            bg-warm-gray-600
          "
        >
          <div class="flex-1 flex flex-col">
            <div
              class="
                flex-shrink-0
                dark:bg-true-gray-700 dark:bg-opacity-25
                bg-warm-gray-700
                py-4
                flex
                items-center
                justify-center
              "
            >
              <Logo
                class="h-8 w-auto dark:text-yellow-400 text-warm-gray-200"
              />
            </div>
            <nav
              aria-label="Sidebar"
              class="py-6 flex flex-col items-center space-y-3"
            >
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.name == $route.name
                    ? 'bg-warm-gray-700 dark:bg-true-gray-700'
                    : 'hover:bg-warm-gray-700 dark:hover:bg-true-gray-700',
                  'flex items-center p-4 rounded-lg text-warm-gray-400',
                ]"
              >
                <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">{{ item.name }}</span>
              </router-link>

              <button
                @click="this.toggleDarkMode()"
                class="
                  hover:bg-warm-gray-700
                  dark:hover:bg-true-gray-700
                  flex
                  items-center
                  p-4
                  rounded-lg
                  text-warm-gray-400
                "
              >
                <SunIcon
                  v-if="this.isDarkMode"
                  class="h-6 w-6"
                  aria-hidden="true"
                />
                <MoonIcon v-else class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">Dark Mode</span>
              </button>

              <router-link
                to="/logout"
                class="
                  hover:bg-warm-gray-700
                  dark:hover:bg-true-gray-700
                  flex
                  items-center
                  p-4
                  rounded-lg
                  text-warm-gray-400
                "
              >
                <LogoutIcon class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">Logout</span>
              </router-link>
            </nav>
          </div>

          <div class="flex-shrink-0 flex pb-5">
            <router-link
              to="/account"
              @click="mobileMenuOpen = false"
              class="flex-shrink-0 w-full"
            >
              <img
                v-if="user.photo"
                class="block mx-auto h-10 w-10 rounded-full"
                :src="user.photo"
                alt=""
              />
              <DefaultUserIcon class="block mx-auto h-12 w-12" v-else />
              <div class="sr-only">
                <p>{{ user.email }}</p>
                <p>Account settings</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 min-w-0 flex flex-col overflow-hidden">
      <!-- Mobile top navigation -->
      <div class="lg:hidden">
        <div
          class="
            dark:bg-true-gray-800
            bg-warm-gray-600
            py-2
            px-4
            flex
            items-center
            justify-between
            sm:px-6
          "
        >
          <div>
            <Logo class="h-8 w-auto dark:text-yellow-400 text-warm-gray-200" />
          </div>
          <div>
            <button
              type="button"
              class="
                -mr-3
                h-12
                w-12
                inline-flex
                items-center
                justify-center
                dark:bg-true-gray-800
                bg-warm-gray-600
                rounded-md
                text-white
                dark:hover:bg-true-gray-700
                hover:bg-warm-gray-700
                focus:outline-none
                focus:ring-2
                focus:ring-inset
                focus:ring-white
              "
              @click="mobileMenuOpen = true"
            >
              <span class="sr-only">Open sidebar</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <main class="flex-1 flex overflow-hidden">
        <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
          <div class="flex-1 flex xl:overflow-hidden">
            <!-- Main content -->
            <div class="flex-1 max-h-screen xl:overflow-y-auto">
              <div class="max-w-full mx-auto py-6 px-4 sm:px-6 lg:py-6 lg:px-6">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/database";
import fb from "firebase/app";
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  HomeIcon,
  ChartBarIcon,
  PuzzleIcon,
  MenuIcon,
  LogoutIcon,
  XIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/vue/outline";

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Exam", href: "/exam", icon: ChartBarIcon },
  { name: "Practice", href: "/practice", icon: PuzzleIcon },
];

import Logo from "@/components/icons/Logo";
import DefaultUserIcon from "@/components/icons/DefaultUserIcon";
import { v5 as uuidv5 } from "uuid";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    LogoutIcon,
    MenuIcon,
    XIcon,
    SunIcon,
    MoonIcon,
    Logo,
    DefaultUserIcon,
  },
  setup() {
    const mobileMenuOpen = ref(false);
    return {
      navigation,
      mobileMenuOpen,
    };
  },
  data() {
    return {
      moment: moment,
      isLogged: false,
      isDarkMode: false,
      user: {
        photo: null,
        email: null,
      },
    };
  },
  methods: {
    toggleDarkMode() {
      if (this.isDarkMode) {
        console.log("turning off dark mode");
        this.isDarkMode = false;
        document.documentElement.classList.remove("dark");
      } else {
        console.log("turning on dark mode");
        this.isDarkMode = true;
        document.documentElement.classList.add("dark");
        localStorage.theme = "dark";
      }
    },
    initPresence(session) {
      // Since I can connect from multiple devices or browser tabs, we store each connection instance separately
      // any time that connectionsRef's value is null (i.e. has no children) I am offline
      var userRef = `users/${firebase.auth().currentUser.uid}/connections`;
      var myConnectionsRef = firebase.database().ref(userRef);
      console.log(myConnectionsRef);

      // stores the timestamp of my last disconnect (the last time I was seen online)
      var lastOnlineRef = firebase.database().ref(userRef);

      var connectedRef = firebase.database().ref(".info/connected");
      connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
          var con = myConnectionsRef.push();

          // When I disconnect, remove this device
          con.onDisconnect().remove();

          // Add this device to my connections list
          // this value could contain info about the device or a timestamp too
          // con.set(true);
          con.set({
            activity: this.$route.name,
            started: fb.database.ServerValue.TIMESTAMP,
            session: session,
          });

          // When I disconnect, update the last time I was seen online
          lastOnlineRef.onDisconnect().set(fb.database.ServerValue.TIMESTAMP);
        }
      });
    },
  },
  created() {
    if (firebase.auth().currentUser) {
      this.isLogged = true;
      this.user.email = firebase.auth().currentUser.email;
      this.user.photo = firebase.auth().currentUser.photoURL
        ? firebase.auth().currentUser.photoURL
        : null;

      // NOTE: IP TRACKING. This one uses 3rd Party API to track IP address.
      // Also user can do multiple access using one IP and can also be spoofed.

      // fetch('https://api.ipify.org?format=json')
      // .then( res => res.json() )
      // .then(({ ip }) => {
      //   console.log(`This device's IP: ${ip}`)
      //   this.initPresence(ip)
      // })
      // .catch(e => {
      //   console.log("Error fetching data from ")
      // })

      // PREFERRED: (inspired by MT): Creating a session id from the Skilltech Namespace and the user's token
      // If a user logs in on another device or browser, this session ID will change.
      // If user is logged in, opens a new tab, it will throw the same session id.
      var session = uuidv5(
        firebase.auth().currentUser.refreshToken,
        process.env.VUE_APP_SKILLTECH_NAMESPACE
      );
      console.log("Session id => ", session);
      this.initPresence(session);
    } else {
      this.isLogged = false;
    }

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      this.isDarkMode = true;
    } else {
      document.documentElement.classList.remove("dark");
      this.isDarkMode = false;
    }
  },
};
</script>