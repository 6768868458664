/**
 * @WARN: Vuex is currently not being used. We might delete if we don't need.
 * This is only here for template purposes.
 */

import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
    }
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {},
})
