const axios = require('axios');
// import axios from "axios";

async function subscribe(subscriber, method){

  console.log("subscriber", subscriber)

  const data = {
    api_key: process.env.VUE_APP_CONVERTKIT_API_KEY,
    email: subscriber.email,
    first_name: subscriber.firstname,
    // last_name: subscriber.lastname
  };

  // console.log({data})
  console.log("method: ", method)

  // @TODO: Update the linkedin ID
  const form_ids = {
    recapture: process.env.VUE_APP_CONVERTKIT_CAAP_RECAPTURE_FORM_ID,
    purchased: process.env.VUE_APP_CONVERTKIT_CAAP_PURCHASED_FORM_ID
  }
  
  // const caap_form_id = process.env.VUE_APP_CONVERTKIT_CAAP_PURCHASED_FORM_ID

  try {
    await axios.post(
      `https://api.convertkit.com/v3/forms/${form_ids[method]}/subscribe`,
      // `https://api.convertkit.com/v3/forms/${caap_form_id}/subscribe`,
      data,
    );
    return {
      statusCode: 200,
      body: 'Email Subscribed',
    };
  } catch (err) {
    console.log("An Error Occured")
    console.log(err.response.status)
    return {
      statusCode: err.response.status,
      body: JSON.stringify({ msg: err.message }),
    };
  }
}

exports.convertkit  = {
  subscribe
}