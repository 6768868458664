<template>
  <!-- Waiting Screen (Just a pulsating Skilltech logo for now ) -->
  <div class="flex flex-col items-center overflow-hidden">
      <Logo class="w-24 animate-pulse dark:text-true-gray-800 text-warm-gray-300" />
  </div>
</template>
<script>
import Logo from "@/components/icons/Logo";

export default {
  components: {
    Logo
  }
}
</script>