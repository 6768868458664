<template>
  <div class="h-100">
    <!-- 
      Display a generic/common loading screen.
      This is only preferred when the waiting time is 
      less than 1 second. i.e. when backend is being fetched for data.
      If it might take more than 1 second, we display a better screen with tips.
      No need to display tips for this one as the user can't read it for a split second.
     -->
    <LoadingScreen v-if="isLoading" class="select-none"/>

    <main v-else class="mt-4 sm:mt-8 pb-8">
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 class="sr-only">Exam</h1>
        
        <!-- 
          User is now taking the exam. 
         -->
        <OngoingExamScreen
          v-if="state=='EXAM_ONGOING'"
          :exam="exam"
          @confirmQuit="openExitModal()" 
          @evaluateExam="evaluateExam()" 
        />

        <!-- 
          Exam is now complete and results are available.
          Pass the modal counter so we can disable the exit button if 
          there are still achievements to show to users.
         -->
        <PostExamScreen
          v-else-if="state=='EXAM_COMPLETE'"
          :achievementModalCounter="achievementModalCounter" 
          :exam="exam"
        />

        <!-- 
          Wrong state. Display generic error screen. 
         -->
        <div v-else>
          <p class="text-sm font-medium text-warm-gray-500">
            An Error occurred during the exam. Invalid STATE.
          </p>
        </div>

      </div>
    </main>

  </div>

  <!-- 
    If user acheived multiple achievements, 
    all of them will be presented sequentially. 
    One after the other.
    
    counter: The number of modals equal to the number of achievements
    achievement: the achievement. the component will adjust from this data.
   -->
  <AchievementModal 
      :achievementModalCounter="achievementModalCounter" 
      :achievement="exam.achievements[achievementModalCounter]" 
      :isAchievementModalOpen="isAchievementModalOpen" 
      @closeAchievementModal="(isAchievementModalOpen = false), showNextAchievement()" 
    />

  <!-- 
    If user exits the exam, show this modal.
   -->
  <ExitModal 
      :isExitModalOpen="isExitModalOpen"
      @closeExitModal="closeExitModal()"
      @quitExam="(isExitModalOpen = false), quitExam()" 
    />
    
</template>

<script>
const NAME = "Exam";

// Firebase Deps
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

// Components Deps
import { ref } from "vue";
// Screens
import LoadingScreen from "@/components/screens/LoadingScreen"
import OngoingExamScreen from "@/components/screens/OngoingExamScreen"
import PostExamScreen from "@/components/screens/PostExamScreen"
// Modals
import AchievementModal from "@/components/modals/AchievementModal"
import ExitModal from "@/components/modals/ExitModal"

export default {
  name: NAME,
  site_title: NAME,
  components: { 
    LoadingScreen, 
    OngoingExamScreen, PostExamScreen, 
    AchievementModal, ExitModal
  },
  setup() {
    const isAchievementModalOpen = ref(false)
    const isExitModalOpen = ref(false)
    return {
      isAchievementModalOpen,
      closeAchievementModal() { isAchievementModalOpen.value = false },
      openAchievementModal() { isAchievementModalOpen.value = true },
      isExitModalOpen,
      closeExitModal() { isExitModalOpen.value = false },
      openExitModal() { isExitModalOpen.value = true },
    }
  },
  data() {
    return {
      isLoading: null,  // Loading means the view is being loaded. Show a loading screen
      isWaiting: null,  // Waiting means the view is doing something. Adjust the UI if needed
      state: null,      // State of the UI
      achievementModalCounter: 0,  // Counter for the Achievement Modals
      canRedirectAway: null,  // flag that the user confirmed the quit after modal is shown. This is used for users when they redirect away.
      redirectTo: null, // If user redirects away, reference the $route.next() function here
      // The Exam Object
      exam: {
        achievements: [
          {
            id: 'full-speedrunner',
            name: 'The Amazing Examinee',
            description: 'You passed the exam with maximum speed and shit...',
            skillpoints: 2500
          },
          // {
          //   id: 'full-retard',
          //   name: 'The Super Retardation',
          //   description: 'Ooops! Looks like you are a retard! Nice...',
          //   skillpoints: 600
          // },
          // {
          //   id: 'full-master',
          //   name: 'The Master of Cointz',
          //   description: 'Chaos is a laddah, my fwend. Love you.',
          //   skillpoints: 9000
          // },
          ]
      },
    }
  },
  methods: {
    
    /**
     * Show the next achievement on the achievements array.
     * If there are no more achievements in the queue, close modal
     */ 
    showNextAchievement(){
      setTimeout(() => { // Set delay to accommodate closing animation of previous modal
        this.achievementModalCounter--;
        if(this.achievementModalCounter < 0) return // do nothing if there's no more achievements
        this.openAchievementModal()
      }, 500);
    },

    // TBD: Initialize Exam
    initializeExam(){
      console.log("Initializing exam...")
      this.isLoading = true;
      setTimeout(() => { // dummy delay for FETCHING DATA from backend
        // populate exam data here
        this.isLoading = false;
        this.state = "EXAM_ONGOING";
      }, 1000);
    },

    // TBD: Evaluate Exam
    evaluateExam(){
      console.log("Evaluating results of exam...")
      this.isLoading = true;
      setTimeout(() => { // dummy delay for FETCHING DATA from backend
        this.isLoading = false;
        this.state = "EXAM_COMPLETE";
        this.canRedirectAway = true;
        this.showAllAchievements() // attempt to show achievements
      }, 1000);
    },

    /**
     * User quits the exam. Mark the exam as cancelled/quit and push to backend.
     * If successful, redirect the user to somewhere
     */
    quitExam(){
      console.log("Quitting exam...")
      this.isLoading = true;
      this.canRedirectAway = true;  // User confirmed this quit. Therefore, beforeRouteLeave will be skipped
      setTimeout(() => { // dummy delay for UPDATING DATA from backend
        if(this.redirectTo){  // If this one is null, use next()=redirectTo() to redirect user
          console.log("Redirecting...")
          this.redirectTo()
          // this.$router.push({ path: this.redirectTo.path })
        }else{
          this.$router.push({ path: '/' }) // Redirect to home
        }
        // this.isLoading = false; // Not needed as user is redirected
      }, 1000);
    },
    
    /**
     * If the results of the exam has achievements, show them to the user 
     * via the Achievement Modal box. If there are multiple achievements,
     * show them one-by-one sequentially.
     */
    showAllAchievements(){
      this.achievementModalCounter = this.exam.achievements.length-1;
      if(this.achievementModalCounter >= 0){
        // there are achievements, display them via AchievementModal
        this.openAchievementModal();
      }
    }

  },

  /**
   * As this view is being mounted, initialize the exam
   */
  mounted() {
    // Prevent auto-refresh. Ask user before proceeding via Browser's beforeUnload.
    // This is only when user refreshes the browser.
    window.addEventListener('beforeunload', (e) => {
      console.log("Window is being refreshed...")
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    })
    this.initializeExam()
  },

  /**
   * Before the user leaves the view,
   * make sure to show him a confirm message. 
   * In this case, confirm if he wants to quit.
   */
  beforeRouteLeave(to, from, next) {
    // If user did not confirm yet, show the modal
    if(!this.canRedirectAway){
      this.redirectTo = next  // pass this function to be invoked later
      this.openExitModal();
    }else{
      next();
    }
  },
  
};
</script>