import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store'; // @WARN: Currently not being used. Store is empty.
import title_mixin from '@/mixins/title';
import firebase from "@/firebase/config";
import '@/assets/css/tailwind.css';

let app;

document.title = "Skilltech App | " + location.host
firebase.auth().onAuthStateChanged(user => {
  (user) ? console.log("User => ", user) : console.log("No user is signed in")
  if (!app) {
    app = createApp(App)
      .use(store) // this is empty for now
      .use(router)
      .mixin(title_mixin)
      .mount('#app')
  }
});

// Bump for CI
